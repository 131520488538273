import {
  FormTab,
  ValidationSummary,
  useTabVisibility,
} from "ra-friendsofbabba";

import DirtyFormAlert from "../../alert/DirtyFormAlert";
import { FacilitatorPointsField } from "../../field";
import React from "react";

const ProjectTab1 = ({ fieldLabel, ...props }) => {
  const visible = useTabVisibility(1);

  return (
    <FormTab {...props} label={fieldLabel("tabs.section_1")}>
      {visible && <ValidationSummary />}
      {visible && <DirtyFormAlert />}
      {visible && (
        <FacilitatorPointsField source="facilitator_points" fullWidth />
      )}
    </FormTab>
  );
};
export default ProjectTab1;
