import { API_URL, APP_SUB_TITLE, APP_TITLE } from "../../config";
import { Divider, Typography } from "@material-ui/core";
import {
  LoginPage as FobLoginPage,
  LocalLoginForm,
  ResetPasswordLinkButton,
  SpidLoginForm,
} from "ra-friendsofbabba";
import React, { useMemo } from "react";

import { Fragment } from "react";
import logo from "../../assets/logo.jpeg";
import { makeStyles } from "@material-ui/core/styles";
import theme from "../../theme";
import { useEffect } from "react";
import { useTranslate } from "react-admin";

const useStyles = makeStyles((theme) => ({
  alert: {
    padding: theme.spacing(1.5),
    maxWidth: 250,
    fontSize: "small",
  },
  padding: {
    padding: theme.spacing(1.5),
    paddingTop: 0,
    paddingBottom: 0,
  },
}));

const Alert = () => {
  const classes = useStyles();
  const translate = useTranslate();
  return (
    <Typography className={classes.alert} variant="body2">
      <span dangerouslySetInnerHTML={{ __html: translate("ra.login.alert") }} />
    </Typography>
  );
};
const Title = () => {
  const classes = useStyles();
  return (
    <Fragment>
      <Typography variant="h6" className={classes.padding}>
        {APP_TITLE}
      </Typography>
      <Typography variant="subtitle2" className={classes.padding}>
        {APP_SUB_TITLE}
      </Typography>
      <Divider />
    </Fragment>
  );
};
const LoginPage = (props) => {
  const isStaffUrl = props.location.search.indexOf("type=staff") > -1;
  const isStaff = useMemo(
    () => isStaffUrl || localStorage.getItem("isStaff") === "true",
    [isStaffUrl]
  );
  useEffect(() => {
    localStorage.setItem("isStaff", isStaffUrl);
  }, [isStaffUrl]);
  return (
    <FobLoginPage
      {...props}
      themeOverride={theme}
      logo={
        <img src={logo} width={250} alt={APP_TITLE} style={{ padding: 16 }} />
      }
    >
      <Title />
      {isStaff && <LocalLoginForm />}
      <SpidLoginForm apiUrl={API_URL} signup="#/signup" />
      {isStaff && <ResetPasswordLinkButton />}
      <Alert />
    </FobLoginPage>
  );
};
export default LoginPage;
