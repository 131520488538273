import "leaflet/dist/leaflet.css";
import "leaflet-geosearch/dist/geosearch.css";

import { Chip, FormHelperText } from "@material-ui/core";
import { Group, GroupItem, GroupTitle, useFieldLabel } from "ra-friendsofbabba";
import { MapContainer, Marker, TileLayer, useMapEvents } from "react-leaflet";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useForm, useFormState } from "react-final-form";

import MapSearchInput from "./MapSearchInput";
import RoomIcon from "@material-ui/icons/Room";
import ZoomInIcon from "@material-ui/icons/ZoomIn";
import { divIcon } from "leaflet";
import { get } from "lodash";
import { makeStyles } from "@material-ui/styles";
import { renderToString } from "react-dom/server";
import { useInput } from "react-admin";
import { useTabVisibility } from "ra-friendsofbabba";

const useStyles = makeStyles((theme) => ({
  map: {
    height: 400,
    marginBottom: theme.spacing(1),
    width: "100%",
    borderRadius: theme.spacing(1),
  },
  llz: {
    float: "right",
    "& .MuiChip-root": {
      marginLeft: theme.spacing(1),
    },
  },
}));
const PositionMarker = ({ position, onClick }) => {
  useMapEvents({
    click(e) {
      onClick(e);
    },
  });
  const icon = divIcon({
    className: `transparent`,
    html: renderToString(
      <RoomIcon fontSize="large" style={{ fontSize: "80px !important" }} />
    ),
    iconAnchor: [40, 70],
  });
  return position === null ? null : <Marker position={position} icon={icon} />;
};

const MapInput = ({
  fullWidth,
  center = [40.842692, 17.055715],
  zoom = 16,
  disabled,
  ...props
}) => {
  const fieldLabel = useFieldLabel({ ...props });

  const {
    meta: { submitError },
  } = useInput({ ...props });
  const classes = useStyles();
  const form = useForm();
  const { values } = useFormState({ subscription: { values: true } });
  const [map, setMap] = useState(null);
  const { mapLat, mapLng, mapZoom, modified } = useMemo(
    () => ({
      mapLat: get(values, "map_lat", center[0]) || center[0],
      mapLng: get(values, "map_lng", center[1]) || center[1],
      mapZoom: get(values, "map_zoom", zoom) || zoom,
      modified: get(values, "modified", null),
    }),
    [values, center, zoom]
  );

  const handleClick = useCallback(
    (position) => {
      if (disabled) {
        return;
      }
      form.batch(() => {
        form.change("map_lat", position.latlng.lat);
        form.change("map_lng", position.latlng.lng);
      });
    },
    [form, disabled]
  );
  const visible = useTabVisibility(0);
  useEffect(() => {
    if (map && visible) {
      map.invalidateSize();
    }
  }, [map, visible]);
  const handleChange = useCallback(
    (position) => {
      form.batch(() => {
        form.change("map_lng", position.x);
        form.change("map_lat", position.y);
        map?.flyTo([position.y, position.x], mapZoom);
      });
    },
    [form, map, mapZoom]
  );
  return (
    <Group wrapper>
      <GroupTitle
        title={fieldLabel(props.source)}
        subTitle={fieldLabel(`${props.source}.help`)}
      />
      <Group>
        <GroupItem lg={12} md={12} sm={12} xs={12}>
          <MapSearchInput
            {...props}
            label={fieldLabel("map_keyword")}
            source={"map_keyword"}
            fullWidth
            disabled={disabled}
            onChange={handleChange}
          />
          <MapContainer
            key={modified}
            center={[mapLat, mapLng]}
            zoom={mapZoom}
            className={classes.map}
            whenCreated={setMap}
            scrollWheelZoom={false}
          >
            <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
            <PositionMarker position={[mapLat, mapLng]} onClick={handleClick} />
          </MapContainer>
          {mapLat && mapLng && (
            <div className={classes.llz}>
              <Chip label={mapLat} />
              <Chip label={mapLng} />
              <Chip icon={<ZoomInIcon />} label={mapZoom} />
            </div>
          )}
          {submitError && <FormHelperText error>{submitError}</FormHelperText>}
        </GroupItem>
      </Group>
    </Group>
  );
};

export default MapInput;
