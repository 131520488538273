import {
  FormTab,
  ValidationSummary,
  useTabVisibility,
} from "ra-friendsofbabba";

import DirtyFormAlert from "../../alert/DirtyFormAlert";
import PrintAlert from "../../alert/PrintAlert";
import React from "react";
import { SignedDocMediaInput } from "../../input";

const ProjectTab4 = ({ fieldLabel, ...props }) => {
  const visible = useTabVisibility(4);

  return (
    <FormTab {...props} label={fieldLabel("tabs.section_4")}>
      {visible && <ValidationSummary />}
      {visible && (
        <DirtyFormAlert message="resources.projects.messages.dirty_form_on_tab4" />
      )}
      {visible && <PrintAlert />}
      {visible && <SignedDocMediaInput source="signed_doc_media" fullWidth />}
    </FormTab>
  );
};
export default ProjectTab4;
