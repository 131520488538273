import {
  AutocompleteArrayInput,
  Group,
  GroupItem,
  GroupTitle,
  useFieldLabel,
  useManyFormatter,
  useManyParser,
} from "ra-friendsofbabba";

import React from "react";
import { ReferenceArrayInput } from "react-admin";

const FacilitatorPointsInput = (props) => {
  const format = useManyFormatter();
  const parse = useManyParser();
  const getLabel = useFieldLabel(props);
  return (
    <Group wrapper {...props}>
      <GroupTitle
        title={getLabel("locations.title")}
        subTitle={getLabel("locations.sub_title")}
      />
      <Group>
        <GroupItem lg={12} md={12} sm={12}>
          <ReferenceArrayInput
            reference="facilitator-points-location"
            filter={{
              not_id: props?.record?.id,
              facilitator_point_type: "location",
              project_id: props?.record?.project_id,
            }}
            format={format}
            parse={parse}
            fullWidth
          >
            <AutocompleteArrayInput optionText="name" optionValue="id" />
          </ReferenceArrayInput>
        </GroupItem>
      </Group>
    </Group>
  );
};
export default FacilitatorPointsInput;
