import { get } from "lodash";
import { useMemo } from "react";
const MAX_TOTAL_COST = 40000;
const MAX_ACTIVITIES_COST = 40000;
const MIN_ACTIVITIES_COST = 38000;
const MAX_EQUIPMENTS_COST = 2000;
const useLimitCostPlan = (props) => {
  const {
    maxValueTotalCost,
    maxValueActivitiesCost,
    minValueActivitiesCost,
    maxValueEquipmentsCost,
    disableCostPlanInput,
  } = useMemo(() => {
    const count = get(props, "record.facilitator_points", []).filter(
      (fp) => fp.facilitator_point_type !== "location"
    ).length;
    return {
      maxValueTotalCost: MAX_TOTAL_COST * count,
      maxValueActivitiesCost: MAX_ACTIVITIES_COST * count,
      minValueActivitiesCost: MIN_ACTIVITIES_COST * count,
      maxValueEquipmentsCost: MAX_EQUIPMENTS_COST * count,
      disableCostPlanInput: count === 0,
    };
  }, [props]);
  return {
    maxValueTotalCost,
    maxValueActivitiesCost,
    minValueActivitiesCost,
    maxValueEquipmentsCost,
    disableCostPlanInput,
  };
};
export default useLimitCostPlan;
