import { Create, Edit, useTranslate } from "react-admin";

import { FacilitatorPointForm } from "../component";

const FacilitatorPointTitle = ({ record }) => {
  const translate = useTranslate();
  const prefix = translate("resources.facilitator-points.name", {
    smart_count: 1,
  });
  return `${prefix} ${record?.name}`;
};
const FacilitatorPointCreate = (props) => (
  <Create {...props}>
    <FacilitatorPointForm />
  </Create>
);

const FacilitatorPointEdit = (props) => (
  <Edit {...props} title={<FacilitatorPointTitle />}>
    <FacilitatorPointForm />
  </Edit>
);

const config = {
  create: FacilitatorPointCreate,
  edit: FacilitatorPointEdit,
};
export default config;
