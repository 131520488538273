import {
  ArrayInput,
  FormDataConsumer,
  SimpleFormIterator,
  required,
  useInput,
  useNotify,
} from "react-admin";
import {
  Group,
  GroupItem,
  GroupTitle,
  MediaInput,
  useFieldLabel,
} from "ra-friendsofbabba";
import React, { useCallback } from "react";

import MunicipalityInput from "./MunicipalityInput";
import ProvinceInput from "./ProvinceInput";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(1),
    paddingTop: 0,
    marginTop: 0,
  },
}));
const PublicAuthorityPartnersInput = ({ ...props }) => {
  useInput({ ...props });
  const fieldLabel = useFieldLabel({ resource: "public-authority-partners" });
  const classes = useStyles();
  const notify = useNotify();
  const handleRejection = useCallback(
    () => notify("ra.message.file_rejected", { type: "error" }),
    [notify]
  );

  return (
    <Group wrapper {...props}>
      <GroupTitle title={fieldLabel("title")} subTitle={fieldLabel("sub")} />
      <Group>
        <GroupItem lg={12} md={12} sm={12}>
          <ArrayInput label="" source={props.source} className={classes.root}>
            <SimpleFormIterator
              disableReordering
              TransitionProps={{ enter: false, exit: false }}
            >
              <FormDataConsumer>
                {({ getSource, formData, scopedFormData, ...rest }) => (
                  <Group wrapper {...rest}>
                    <Group fullWidth>
                      <GroupItem lg={2} md={3} sm={4} xs={12}>
                        <ProvinceInput
                          label={fieldLabel("province")}
                          source={getSource("province")}
                          validate={required()}
                          disabled={props.disabled}
                        />
                      </GroupItem>
                      <GroupItem lg={6} md={7} sm={8} xs={12}>
                        <MunicipalityInput
                          label={fieldLabel("municipality_id")}
                          source={getSource("municipality_id")}
                          validate={required()}
                          deps={{ province: getSource("province") }}
                          disabled={props.disabled}
                        />
                      </GroupItem>
                    </Group>
                    <Group fullWidth>
                      <GroupItem lg={12} md={12} sm={12} xs={12}>
                        <MediaInput
                          key={getSource("media")}
                          label={fieldLabel("media")}
                          source={getSource("media")}
                          helperText={fieldLabel("media.help")}
                          title="filename"
                          accept=".pdf,.p7m"
                          disabled={props.disabled}
                          validate={required()}
                          record={props.disabled ? formData : rest?.record}
                          options={{
                            onDropRejected: handleRejection,
                          }}
                        />
                      </GroupItem>
                    </Group>
                  </Group>
                )}
              </FormDataConsumer>
            </SimpleFormIterator>
          </ArrayInput>
        </GroupItem>
      </Group>
    </Group>
  );
};
export default PublicAuthorityPartnersInput;
