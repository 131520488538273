import {
  FormTab,
  ValidationSummary,
  useTabVisibility,
} from "ra-friendsofbabba";

import DirtyFormAlert from "../../alert/DirtyFormAlert";
import { Fp } from "../../input";
import React from "react";

const FacilitatorPointTab1 = ({ fieldLabel, ...props }) => {
  const visible = useTabVisibility(1);
  return (
    <FormTab {...props} label={fieldLabel("tabs.section_2")}>
      {visible && <ValidationSummary />}
      {visible && <DirtyFormAlert />}
      {visible && (
        <Fp.GeneralConditionInput
          source="facilitator_point_general_condition"
          fullWidth
        />
      )}
    </FormTab>
  );
};
export default FacilitatorPointTab1;
