import { Link, makeStyles } from "@material-ui/core";
import React, { useCallback } from "react";
import { useListContext, useTranslate } from "react-admin";

import { API_URL } from "../../config";
import Button from "@material-ui/core/Button";
import GetAppIcon from "@material-ui/icons/GetApp";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import { getToken } from "ra-friendsofbabba";

const useStyles = makeStyles((theme) => ({
  button: {
    marginLeft: theme.spacing(2),
  },
}));
const ExportMenuButton = ({
  resource,
  filterValues,
  choices = ["zip", "xlsx"],
}) => {
  const translate = useTranslate();
  const classes = useStyles();
  const { currentSort, total } = useListContext();
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const createHref = useCallback(
    (type) =>
      `${API_URL}/${resource}/export.${type}?token=${getToken()}&${Object.keys(
        filterValues
      )
        .filter(
          (name) =>
            filterValues[name] !== undefined && filterValues[name] !== null
        )
        .map((name) => `${name}=${filterValues[name]}`)
        .join("&")}&sort=${currentSort.field}&direction=${
        currentSort.order
      } &page=1&limit=1000`,
    [resource, filterValues, currentSort]
  );

  return (
    <div>
      <Button
        size="small"
        color="primary"
        variant="contained"
        aria-controls="export-menu"
        aria-haspopup="true"
        className={classes.button}
        disabled={total === 0}
        onClick={handleClick}
        startIcon={<GetAppIcon />}
      >
        {translate("ra.action.export")}
      </Button>
      <Menu
        id="export-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {choices.map((choice) => (
          <MenuItem
            key={choice}
            component={Link}
            onClick={handleClose}
            target="_blank"
            href={createHref(choice)}
          >
            {translate(`resources.${resource}.export_to.${choice}`, {
              _: choice.toUpperCase(),
            })}
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
};

export default ExportMenuButton;
