import { API_URL, APP_TITLE } from "../../config";
import { SignupPage as FobSignupPage, SpidSignupForm } from "ra-friendsofbabba";

import theme from "../../theme";

const SignupPage = (props) => (
  <FobSignupPage {...props} themeOverride={theme} title={APP_TITLE}>
    <SpidSignupForm apiUrl={API_URL} />
  </FobSignupPage>
);

export default SignupPage;
