import * as Icons from "@material-ui/icons";

import { CrudContext, Menu as FobMenu } from "ra-friendsofbabba";
import React, { useContext, useMemo } from "react";

import { useQuery } from "react-admin";

const getBadges = (data) =>
  data
    ? Object.keys(data).reduce(
        (badges, k) =>
          data[k].badge != null ? { ...badges, [k]: data[k].badge } : badges,
        {}
      )
    : {};

const Menu = (props) => {
  const { data } = useContext(CrudContext);
  const badges = useMemo(() => getBadges(data), [data]);
  const { data: pages } = useQuery({
    resource: "pages/menu",
    type: "get",
  });

  return (
    <FobMenu
      {...props}
      order={{
        dashboard: 10,
        admin: 100,
        pages: 10,
      }}
      items={pages?.data?.map((page) => ({
        name: page.name,
        path: `/pages/${page.id}/show`,
        label: page.name,
        icon: Icons.Info,
        group: "pages",
        localize: false,
      }))}
      badges={badges}
    ></FobMenu>
  );
};

export default Menu;
