import {
  ArrayInput,
  FormDataConsumer,
  SimpleFormIterator,
  required,
  useInput,
} from "react-admin";
import {
  DebouncedTextInput,
  Group,
  GroupItem,
  GroupTitle,
  useFieldLabel,
} from "ra-friendsofbabba";

import MunicipalityInput from "../MunicipalityInput";
import ProvinceInput from "../ProvinceInput";
import React from "react";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(1),
    paddingTop: 0,
    marginTop: 0,
  },
}));

const RotationsInput = (props) => {
  useInput(props);
  const fieldLabel = useFieldLabel({ resource: "facilitator-point-rotations" });
  const classes = useStyles();
  return (
    <Group wrapper {...props}>
      <GroupTitle title={fieldLabel("title")} subTitle={fieldLabel("sub")} />
      <Group>
        <GroupItem lg={12} md={12} sm={12}>
          <ArrayInput label="" source={props.source} className={classes.root}>
            <SimpleFormIterator
              disableReordering
              TransitionProps={{ enter: false, exit: false }}
            >
              <FormDataConsumer>
                {({ getSource }) => (
                  <Group wrapper {...props}>
                    <Group fullWidth>
                      <GroupItem lg={2} md={3} sm={4}>
                        <ProvinceInput
                          label={fieldLabel("province")}
                          source={getSource("province")}
                          validate={required()}
                        />
                      </GroupItem>
                      <GroupItem lg={6} md={7} sm={8}>
                        <MunicipalityInput
                          label={fieldLabel("municipality_id")}
                          source={getSource("municipality_id")}
                          validate={required()}
                          deps={{ province: getSource("province") }}
                        />
                      </GroupItem>
                    </Group>
                    <Group fullWidth>
                      <GroupItem lg={8} md={10} sm={12}>
                        <DebouncedTextInput
                          label={fieldLabel("address")}
                          source={getSource("address")}
                          validate={required()}
                          maxLength={100}
                        />
                      </GroupItem>
                    </Group>
                  </Group>
                )}
              </FormDataConsumer>
            </SimpleFormIterator>
          </ArrayInput>
        </GroupItem>
      </Group>
    </Group>
  );
};
export default RotationsInput;
