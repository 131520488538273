import {
  Group,
  GroupItem,
  GroupTitle,
  ReferenceListField,
  TextField,
  useFieldLabel,
  useWorkflowInput,
} from "ra-friendsofbabba";
import React, { Fragment, useMemo } from "react";

import { DownloadButton } from "../button";
import useFormPermission from "../input/useFormPermission";

const ProjectMediaField = ({ ...props }) => {
  const fieldLabel = useFieldLabel({ resource: "projects-media" });
  const { visible, disabled } = useWorkflowInput({
    ...props,
    source: "media",
  });
  const hasFormPermission = useFormPermission();
  const hasMediaPermission = useMemo(
    () => hasFormPermission("media"),
    [hasFormPermission]
  );
  if (!visible) {
    return null;
  }
  return (
    <Fragment>
      <GroupTitle
        title={fieldLabel("media")}
        subTitle={fieldLabel("media.help")}
      />
      <Group {...props}>
        <GroupItem lg={12} md={12} sm={12} xs={12}>
          <ReferenceListField
            addLabel={false}
            createLabel="ra.action.add"
            reference="projects-media"
            target="project_id"
            tab={3}
            mobileBreakpoint="sm"
            mobilePrimaryText={"media.filename"}
            create={!disabled && hasMediaPermission}
            modify={!disabled && hasMediaPermission}
            remove={!disabled && hasMediaPermission}
          >
            <TextField source="media.filename" />
            <DownloadButton />
          </ReferenceListField>
        </GroupItem>
      </Group>
    </Fragment>
  );
};

export default ProjectMediaField;
