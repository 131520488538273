import "./theme.css";

import * as Components from "./component";
import * as Icons from "@material-ui/icons";

import { API_URL, ENVIRONMENT } from "./config";
import { Admin, Loading, Resource, RouteWithoutLayout } from "react-admin";
import {
  CrudProvider,
  CrudResource,
  WorkflowProvider,
  createI18nProvider,
  useAuthProvider,
  useDataProvider,
  useI18nCatcher,
  useI18nLanguages,
} from "ra-friendsofbabba";

import Layout from "./component/Layout";
import LoginPage from "./component/page/LoginPage";
import PageShow from "./component/show/PageShow";
import ProfilePage from "./component/page/ProfilePage";
import React from "react";
import ResetPasswordPage from "./component/page/ResetPasswordPage";
import { Route } from "react-router-dom";
import SignupPage from "./component/page/SignupPage";
import facilitatorPoint from "./resource/facilitator-point";
import project from "./resource/project";
import theme from "./theme";

const App = () => {
  const apiUrl = API_URL;
  const languages = useI18nLanguages({ apiUrl });

  // Allow i18n to intercept and send unlocalized messages to the server.
  useI18nCatcher({ apiUrl, loading: languages?.loading });

  const dataProvider = useDataProvider({
    apiUrl,
    fileFields: [
      "media",
      "signed_doc_media",
      "expression_of_interest_media",
      "private_expression_of_interest_doc_media",
    ],
  });
  const authProvider = useAuthProvider({ apiUrl });
  if (languages?.loading) {
    return (
      <Loading loadingPrimary="Loading" loadingSecondary="Please wait..." />
    );
  }

  return (
    <WorkflowProvider apiUrl={apiUrl}>
      <CrudProvider apiUrl={apiUrl} components={Components}>
        <Admin
          initialState={{}}
          theme={theme}
          layout={Layout}
          loginPage={LoginPage}
          customRoutes={[
            <RouteWithoutLayout
              key="signup"
              path="/signup"
              component={SignupPage}
            />,
            <RouteWithoutLayout
              key="reset-password"
              path="/reset-password"
              component={ResetPasswordPage}
            />,
            <Route key="profile" path="/profile" component={ProfilePage} />,
          ]}
          dataProvider={dataProvider}
          authProvider={authProvider}
          i18nProvider={createI18nProvider({
            languages: languages.data,
            locale: "it",
          })}
        >
          <CrudResource
            name="notifications"
            group="dashboard"
            icon={Icons.NotificationImportant}
          />
          <Resource name="projects" {...project} />
          <Resource name="municipalities/provinces" />
          <Resource name="municipalities" group="admin" />
          <Resource name="facilitator-points-location" />
          <Resource name="facilitator-points" {...facilitatorPoint} />
          <Resource name="facilitator-point-space-types" />
          <Resource name="facilitator-point-delivery-method-types" />
          <Resource name="reservation-methods" />
          <CrudResource name="projects-media" list={null} />

          <Resource name="workflow/transactions/projects" />
          <Resource name="roles" />
          <Resource name="languages" />
          <CrudResource
            name="project-time-windows"
            group="admin"
            roles={["admin"]}
            components={Components}
          />
          <CrudResource
            name="command-log-rows"
            group="admin"
            roles={["admin"]}
          />
          <CrudResource
            name="pages"
            group="admin"
            roles={["admin"]}
            icon={Icons.Pages}
            show={PageShow}
          />
          <CrudResource
            name="language-messages"
            group="admin"
            roles={ENVIRONMENT !== "production" ? ["admin"] : ["?"]}
            icon={Icons.FlagOutlined}
          />
          <CrudResource
            name="users"
            group="admin"
            roles={["admin"]}
            icon={Icons.AccountCircle}
          />
        </Admin>
      </CrudProvider>
    </WorkflowProvider>
  );
};
export default App;
