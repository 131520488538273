import { MediaInput, useWorkflowInput } from "ra-friendsofbabba";
import React, { useMemo } from "react";

import useFormPermission from "./useFormPermission";

const SignedDocMediaInput = (props) => {
  const { disabled } = useWorkflowInput(props);
  const hasFormPermission = useFormPermission();
  const hasPermission = useMemo(
    () => hasFormPermission(props.source),
    [hasFormPermission, props?.source]
  );
  return (
    <MediaInput
      {...props}
      title="filename"
      accept=".pdf,.p7m"
      disabled={disabled || !hasPermission}
      isRequired
    />
  );
};
export default SignedDocMediaInput;
