import { Alert, AlertTitle } from "ra-friendsofbabba";

import { PrintButton } from "../button";
import React from "react";
import { Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useTranslate } from "react-admin";

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  message: {
    width: "100%",
  },
  button: {
    marginTop: theme.spacing(1),
  },
}));
const PrintAlert = ({ resource = "projects", ...props }) => {
  const translate = useTranslate();
  const classes = useStyles();
  const disabled = props?.record?.transaction?.state === "sent";
  return (
    <Alert elevation={1} severity="info" className={classes.root}>
      <AlertTitle>
        {translate(`resources.${resource}.alert.print.title`)}
      </AlertTitle>
      <Typography variant="body1" className={classes.message}>
        {translate(`resources.${resource}.alert.print.message`)}
      </Typography>
      <PrintButton
        {...props}
        disabled={disabled}
        resource={resource}
        className={classes.button}
        label={"ra.action.generate_pdf"}
      />
    </Alert>
  );
};
export default PrintAlert;
