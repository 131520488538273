import { AutocompleteInput, useTranslate } from "react-admin";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";

import { OpenStreetMapProvider } from "leaflet-geosearch";
import { get } from "lodash";
import { useDebounce } from "ra-friendsofbabba";
import { useForm } from "react-final-form";

const search = async (query) => {
  const provider = new OpenStreetMapProvider();
  const results = await provider.search({
    query,
  });
  return results;
};
const MapSearchInput = ({ onChange, ...props }) => {
  const [choices, setChoices] = useState([]);
  const [filter, setFilter] = useState("");
  const value = get(props?.record, props?.source);
  const form = useForm();
  const debouncedFilter = useDebounce(filter, 500);
  const didMount = useRef(false);
  useEffect(() => {
    if (!didMount.current) {
      didMount.current = true;
      return;
    }
    if (!debouncedFilter || debouncedFilter.length < 3) {
      return;
    }
    async function fetchData() {
      const results = await search(debouncedFilter);
      setChoices(
        results.map((r) => ({
          id: r.raw.place_id,
          ...r,
        }))
      );
    }
    fetchData();
  }, [debouncedFilter]);

  const handleChange = useCallback(
    (id) => {
      const target = choices.find((c) => c.id === id);
      if (target && onChange) {
        onChange(target);
      }
      form.change(props.source, target.label);
    },
    [choices, onChange, form, props.source]
  );
  const handleMatchSuggestion = useCallback(() => true, []);
  const translate = useTranslate();
  const lastKeyword = useMemo(
    () =>
      translate(`resources.facilitator-points.fields.map_keyword.last`, {
        last: value || "",
      }),
    [value, translate]
  );
  return (
    <AutocompleteInput
      {...props}
      source="__keyword__"
      resettable
      choices={choices}
      optionText={"label"}
      optionValue={"id"}
      setFilter={setFilter}
      matchSuggestion={handleMatchSuggestion}
      onChange={handleChange}
      helperText={lastKeyword}
    />
  );
};

export default MapSearchInput;
