import { BooleanInput, required } from "react-admin";
import {
  DebouncedDateInput,
  DebouncedTextInput,
  Group,
  GroupItem,
  GroupTitle,
  MediaInput,
  useFieldLabel,
} from "ra-friendsofbabba";
import { Fp, MunicipalityInput, ProvinceInput } from "../../input";
import React, { useCallback, useEffect, useMemo } from "react";
import { useForm, useFormState } from "react-final-form";

import MapInput from "../../input/MapInput";
import { get } from "lodash";
import moment from "moment";

const FacilitatorPointInput = (props) => {
  const fieldLabel = useFieldLabel({ resource: "facilitator-points" });
  const { values } = useFormState({ subscription: { values: true } });
  const form = useForm();
  const { isPrivate, isRotation, today } = useMemo(() => {
    const isPrivate = get(values, "handler") === "private";
    const isRotation = get(values, "facilitator_point_type") === "rotation";
    const today = new Date();
    return { isPrivate, isRotation, today };
  }, [values]);
  const futureDate = useCallback(
    (message = "ra.validation.future_date") =>
      (value) =>
        moment(value).isBefore(today) && message,
    [today]
  );

  useEffect(() => {
    if (!isPrivate) {
      form.batch(() => {
        form.change("private_name", null);
        form.change("private_code", null);
        form.change("private_expiry_date", null);
        form.change("private_description_of_assignment_type", null);
      });
    }
  }, [form, isPrivate]);
  return (
    <Group wrapper {...props}>
      <GroupTitle
        title={fieldLabel("type")}
        subTitle={fieldLabel("type.help")}
      />
      <Group fullWidth>
        <GroupItem lg={12} md={12} sm={12}>
          <Fp.TypeInput
            source="facilitator_point_type"
            helperText={fieldLabel("facilitator_point_type.help")}
            isRequired
          />
        </GroupItem>
      </Group>

      <GroupTitle
        title={fieldLabel("general")}
        subTitle={fieldLabel("general.help")}
      />
      <Group fullWidth>
        <GroupItem lg={4} md={4}>
          <DebouncedTextInput
            source="name"
            maxLength={200}
            isRequired
            validate={required()}
          />
        </GroupItem>
        <GroupItem lg={4} md={4}>
          <Fp.SpaceTypeInput
            source="facilitator_point_space_type_id"
            isRequired
          />
        </GroupItem>
        <GroupItem lg={4} md={4}>
          <Fp.SpaceTypeCustomInput
            source="facilitator_point_space_custom"
            dep="facilitator_point_space_type_id"
            maxLength={100}
            isRequired
          />
        </GroupItem>
      </Group>
      <Group fullWidth>
        <GroupItem lg={2} md={3} sm={4}>
          <ProvinceInput source="province" validate={required()} />
        </GroupItem>
        <GroupItem lg={6} md={7} sm={8}>
          <MunicipalityInput
            source="municipality_id"
            deps={{ province: "province" }}
            validate={required()}
            isRequired
          />
        </GroupItem>
        <GroupItem lg={8} md={10} sm={12}>
          <DebouncedTextInput
            source="address"
            maxLength={100}
            isRequired
            validate={required()}
          />
        </GroupItem>
      </Group>
      <Group fullWidth>
        <GroupItem lg={12} md={12} sm={12} xs={12}>
          <BooleanInput source="is_compliant" isRequired />
        </GroupItem>
      </Group>
      <Group fullWidth>
        <GroupItem lg={12} md={12} sm={12}>
          <MapInput source="map" label={fieldLabel("map")} isRequired />
        </GroupItem>
      </Group>

      {isRotation && (
        <Fp.FacilitatorPointsInput
          label={fieldLabel("locations")}
          source="locations"
          fullWidth
          isRequired
        />
      )}

      <GroupTitle
        title={fieldLabel("management")}
        subTitle={fieldLabel("management.help")}
      />
      <Group>
        <GroupItem lg={12} md={12} sm={12}>
          <Fp.HandlerInput
            source="handler"
            helperText={fieldLabel("handler.help")}
            isRequired
          />
        </GroupItem>
      </Group>
      {isPrivate && (
        <Group wrapper fullWidth>
          <GroupTitle title={fieldLabel("private")} />
          <Group>
            <GroupItem lg={8} md={8} sm={12}>
              <DebouncedTextInput
                source="private_name"
                helperText={fieldLabel("private_name.help")}
                maxLength={100}
                isRequired
              />
            </GroupItem>
            <GroupItem lg={4} md={4} sm={12}>
              <DebouncedTextInput
                source="private_code"
                helperText={fieldLabel("private_code.help")}
                maxLength={20}
                isRequired
              />
            </GroupItem>
          </Group>
          <Group>
            <GroupItem lg={12} md={12} sm={12}>
              <DebouncedTextInput
                source="private_description_of_assignment_type"
                helperText={fieldLabel(
                  "private_description_of_assignment_type.help"
                )}
                maxLength={1000}
                isRequired
                multiline
              />
            </GroupItem>
          </Group>
          <Group>
            <GroupItem>
              <DebouncedDateInput
                source="private_expiry_date"
                helperText={fieldLabel("private_expiry_date.help")}
                validate={futureDate()}
              />
            </GroupItem>
          </Group>
          <Group>
            <GroupItem lg={12} md={12} sm={12} xs={12}>
              <MediaInput
                source="private_expression_of_interest_doc_media"
                title="filename"
                helperText={fieldLabel(
                  "private_expression_of_interest_doc_media.help"
                )}
              />
            </GroupItem>
          </Group>
        </Group>
      )}
    </Group>
  );
};
export default FacilitatorPointInput;
