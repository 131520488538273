import React from "react";
import { ReferenceSelectInput } from "ra-friendsofbabba";

const SpaceTypeInput = ({ ...props }) => (
  <ReferenceSelectInput
    {...props}
    reference="facilitator-point-space-types"
    sort={{ field: "id", order: "ASC" }}
    optionText="name"
    optionValue="id"
  />
);
export default SpaceTypeInput;
