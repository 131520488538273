// in src/posts.js

import * as React from "react";

import { Show as RaShow, SimpleShowLayout } from "react-admin";

import { PageShowField } from "../field";

const PageShow = (props) => {
  const isAdmin = React.useMemo(
    () => (props.permissions ? props.permissions(["admin"]) : false),
    [props]
  );
  const additionalProps = isAdmin ? {} : { actions: null };
  return (
    <RaShow {...props} {...additionalProps}>
      <SimpleShowLayout>
        <PageShowField />
      </SimpleShowLayout>
    </RaShow>
  );
};

export default PageShow;
