import { LongTextField } from "ra-friendsofbabba";
import React from "react";
import { get } from "lodash";
import { useTranslate } from "react-admin";

const FacilitatorPointTypeField = ({ source, record }) => {
  const translate = useTranslate();
  const type = get(record, source);
  return (
    <LongTextField
      minWidth={150}
      source="translation"
      record={{
        translation: translate(
          `resources.facilitator-points.fields.type.${type}`
        ),
      }}
    />
  );
};

export default FacilitatorPointTypeField;
