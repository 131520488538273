import { RadioButtonGroupInput } from "react-admin";
import React from "react";
import { makeStyles } from "@material-ui/core/styles";

const defaultChoices = [
  {
    id: "permanent",
    name: "resources.facilitator-points.fields.type.permanent",
  },
  {
    id: "location",
    name: "resources.facilitator-points.fields.type.location",
  },
  {
    id: "rotation",
    name: "resources.facilitator-points.fields.type.rotation",
  },
];

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(1),
    "& .MuiFormControlLabel-root": {
      clear: "both",
      width: "100%",
    },
  },
}));
const TypeInput = (props) => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <RadioButtonGroupInput {...props} choices={defaultChoices} />
    </div>
  );
};
export default TypeInput;
