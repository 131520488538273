import {
  DebouncedNumberInput,
  DebouncedTextInput,
  Group,
  GroupItem,
  GroupTitle,
  useFieldLabel,
} from "ra-friendsofbabba";
import React, { useCallback } from "react";

import { get } from "lodash";
import { minValue } from "react-admin";

const validateWorkstation =
  (fieldSource, message = "ra.validation.min_2") =>
  (value, values) => {
    const fields = [
      fieldSource("number_of_workstations"),
      fieldSource("number_of_workstations_to_complete"),
    ];
    const sum = fields.reduce(
      (acc, field) => acc + (get(values, field, 0) || 0),
      0
    );
    console.info("sum:", sum);
    if (sum < 2) {
      return message;
    }
    return undefined;
  };

const GeneralConditionInput = (props) => {
  const fieldLabel = useFieldLabel({
    resource: "facilitator-point-general-conditions",
  });
  const fieldSource = useCallback(
    (source) => `${props.source}.${source}`,
    [props.source]
  );

  return (
    <Group wrapper {...props}>
      <GroupTitle
        title={fieldLabel("general")}
        subTitle={fieldLabel("general.help")}
      />
      <Group fullWidth>
        <GroupItem lg={4} md={5} sm={6}>
          <DebouncedNumberInput
            label={fieldLabel("space_opening_hours")}
            source={fieldSource("space_opening_hours")}
            helperText={fieldLabel("space_opening_hours.help")}
            isRequired
            validate={minValue(0)}
          />
        </GroupItem>
      </Group>
      <Group fullWidth>
        <GroupItem lg={12} md={12} sm={12}>
          <DebouncedTextInput
            label={fieldLabel("description_of_opening_hours")}
            source={fieldSource("description_of_opening_hours")}
            helperText={fieldLabel("description_of_opening_hours.help")}
            isRequired
            multiline
            maxLength={1000}
          />
        </GroupItem>
        <GroupItem lg={12} md={12} sm={12}>
          <DebouncedTextInput
            label={fieldLabel("description_of_public_space")}
            source={fieldSource("description_of_public_space")}
            helperText={fieldLabel("description_of_public_space.help")}
            isRequired
            multiline
            maxLength={1000}
          />
        </GroupItem>
      </Group>
      <Group fullWidth>
        <GroupItem lg={4} md={5} sm={6}>
          <DebouncedNumberInput
            label={fieldLabel("number_of_workstations")}
            source={fieldSource("number_of_workstations")}
            helperText={fieldLabel("number_of_workstations.help")}
            validate={[minValue(0), validateWorkstation(fieldSource)]}
            isRequired
          />
        </GroupItem>
        <GroupItem lg={4} md={5} sm={6}>
          <DebouncedNumberInput
            label={fieldLabel("number_of_workstations_to_complete")}
            source={fieldSource("number_of_workstations_to_complete")}
            helperText={fieldLabel("number_of_workstations_to_complete.help")}
            validate={[minValue(0), validateWorkstation(fieldSource)]}
            isRequired
          />
        </GroupItem>
      </Group>
      <Group fullWidth>
        <GroupItem lg={6} md={6} sm={12}>
          <DebouncedTextInput
            label={fieldLabel("description_of_rooms")}
            source={fieldSource("description_of_rooms")}
            helperText={fieldLabel("description_of_rooms.help")}
            isRequired
            maxLength={1000}
            multiline
          />
        </GroupItem>
        <GroupItem lg={6} md={6} sm={12}>
          <DebouncedTextInput
            label={fieldLabel("description_of_motivations")}
            source={fieldSource("description_of_motivations")}
            helperText={fieldLabel("description_of_motivations.help")}
            isRequired
            maxLength={500}
            multiline
          />
        </GroupItem>
      </Group>
    </Group>
  );
};
export default GeneralConditionInput;
