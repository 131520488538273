import React, { useMemo } from "react";
import { useForm, useFormState } from "react-final-form";

import { DebouncedTextInput } from "ra-friendsofbabba";
import PropTypes from "prop-types";
import SpaceTypeInput from "./SpaceTypeInput";
import { get } from "lodash";
import { useEffect } from "react";
import { useSelector } from "react-redux";

const SpaceTypeCustomInput = ({ dep, ...props }) => {
  const data = useSelector((state) =>
    get(state, "admin.resources.facilitator-point-space-types.data")
  );
  const { values } = useFormState({ subscription: { values: true } });
  const form = useForm();
  const isCustom = useMemo(
    () => get(data, `${get(values, dep)}.is_custom`, false),
    [dep, values, data]
  );

  useEffect(() => {
    if (!isCustom) {
      form.change(props.source, null);
    }
  }, [form, isCustom, props]);

  if (!isCustom) {
    return null;
  }
  return <DebouncedTextInput {...props} />;
};
SpaceTypeInput.propTypes = {
  dep: PropTypes.string,
};
export default SpaceTypeCustomInput;
