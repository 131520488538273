import React, { useMemo } from "react";
import { Toolbar, useFieldLabel, useSaveMutation } from "ra-friendsofbabba";

import FacilitatorPointTab0 from "./tab/FacilitatorPointTab0";
import FacilitatorPointTab1 from "./tab/FacilitatorPointTab1";
import FacilitatorPointTab2 from "./tab/FacilitatorPointTab2";
import { TabbedForm } from "react-admin";
import { get } from "lodash";

const FacilitatorPointForm = ({ create = false, ...props }) => {
  const save = useSaveMutation({
    ...props,
    refresh: true,
  });
  const fieldLabel = useFieldLabel({ ...props });
  const isNew = useMemo(
    () => (get(props, "record.id", null) ? false : true),
    [props]
  );
  return (
    <TabbedForm
      {...props}
      save={save}
      initialValues={{
        ...props?.record,
        handler: "public",
        facilitator_point_type: "permanent",
        facilitator_point_delivery_method: {
          description_of_motivations: "",
        },
        facilitator_point_general_condition: {
          description_of_opening_hours: "",
        },
      }}
      toolbar={
        <Toolbar
          backTab={1}
          backReference="projects"
          backReferenceTarget="project_id"
        />
      }
    >
      <FacilitatorPointTab0 fieldLabel={fieldLabel} />
      {!isNew && <FacilitatorPointTab1 fieldLabel={fieldLabel} />}
      {!isNew && <FacilitatorPointTab2 fieldLabel={fieldLabel} />}
    </TabbedForm>
  );
};
export default FacilitatorPointForm;
