import {
  FormTab,
  ValidationSummary,
  useTabVisibility,
} from "ra-friendsofbabba";

import { CostPlanInput } from "../../input";
import DirtyFormAlert from "../../alert/DirtyFormAlert";
import React from "react";

const ProjectTab2 = ({ fieldLabel, ...props }) => {
  const visible = useTabVisibility(2);

  return (
    <FormTab {...props} label={fieldLabel("tabs.section_2")}>
      {visible && <ValidationSummary />}
      {visible && <DirtyFormAlert />}
      {visible && <CostPlanInput source="cost_plan" fullWidth />}
    </FormTab>
  );
};
export default ProjectTab2;
