import { FormTab, useTabVisibility } from "ra-friendsofbabba";

import DirtyFormAlert from "../../alert/DirtyFormAlert";
import ProjectMediaField from "../../field/ProjectMediaField";
import React from "react";

const ProjectTab3 = ({ fieldLabel, ...props }) => {
  const visible = useTabVisibility(3);

  return (
    <FormTab {...props} label={fieldLabel("tabs.section_3")}>
      {visible && <DirtyFormAlert />}
      {visible && <ProjectMediaField source="media" fullWidth />}
    </FormTab>
  );
};
export default ProjectTab3;
