import {
  Group,
  GroupItem,
  GroupTitle,
  LongTextField,
  ReferenceListField,
  useFieldLabel,
  useWorkflowInput,
} from "ra-friendsofbabba";
import React, { Fragment, useMemo } from "react";

import FacilitatorPointHandlerField from "./FacilitatorPointTypeField";
import { TextField } from "react-admin";
import useFormPermission from "../input/useFormPermission";

const FacilitatorPointsField = (props) => {
  const fieldLabel = useFieldLabel({ resource: "facilitator-points" });
  const { disabled } = useWorkflowInput({
    ...props,
    source: "facilitator_points",
  });
  const hasFormPermission = useFormPermission();
  const hasFacilitatorPointsPermission = useMemo(
    () => hasFormPermission("facilitator_points"),
    [hasFormPermission]
  );
  return (
    <Fragment>
      <GroupTitle
        title={fieldLabel("facilitator_points")}
        subTitle={fieldLabel("facilitator_points.help")}
      />
      <Group {...props}>
        <GroupItem lg={12} md={12} sm={12} xs={12}>
          <ReferenceListField
            tab={1}
            addLabel={false}
            createLabel="ra.action.add"
            reference="facilitator-points"
            target="project_id"
            mobileBreakpoint="sm"
            mobilePrimaryText="name"
            mobileSecondaryText="province"
            mobileTertiaryText="municipality.name"
            create={!disabled && hasFacilitatorPointsPermission}
            modify={!disabled && hasFacilitatorPointsPermission}
            remove={!disabled && hasFacilitatorPointsPermission}
          >
            <TextField source="index" label="" />
            <LongTextField source="name" minWidth={120} />
            <FacilitatorPointHandlerField
              source="facilitator_point_type"
              sortable={false}
            />
            <LongTextField source="province" />
            <LongTextField
              source="municipality.name"
              label={fieldLabel("municipality_id")}
              sortBy="Municipalities.name"
            />
            <LongTextField source="address" />
          </ReferenceListField>
        </GroupItem>
      </Group>
    </Fragment>
  );
};
export default FacilitatorPointsField;
