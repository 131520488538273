import {
  Alert,
  AlertTitle,
  DebouncedTextInput,
  Group,
  GroupItem,
  GroupTitle,
  useFieldLabel,
  useWorkflowInput,
} from "ra-friendsofbabba";
import React, { useCallback, useMemo } from "react";
import { maxValue, minValue, useTranslate } from "react-admin";

import MoneyInput from "./MoneyInput";
import { Typography } from "@material-ui/core";
import useFormPermission from "./useFormPermission";
import useLimitCostPlan from "./useLimitCostPlan";

const validate = (_, values) => {
  const totalCost = parseFloat(values?.cost_plan?.total_cost);
  const activitiesCost = parseFloat(values?.cost_plan?.activities_cost);
  const equipmentsCost = parseFloat(values?.cost_plan?.equipments_cost);

  if (
    totalCost !== activitiesCost + equipmentsCost &&
    totalCost &&
    activitiesCost &&
    equipmentsCost
  ) {
    return {
      message: "ra.validation.not_equal_total_cost",
      args: { totalCost },
    };
  }
};

const CostPlanAlert = () => {
  const translate = useTranslate();
  return (
    <Alert elevation={1} severity="warning">
      <AlertTitle>{translate(`resources.cost-plan.alert.title`)}</AlertTitle>
      <Typography variant="body1">
        {translate(`resources.cost-plan.alert.message`)}
      </Typography>
    </Alert>
  );
};

const CostPlanInput = (props) => {
  const fieldLabel = useFieldLabel({
    resource: "cost-plans",
  });
  const fieldSource = useCallback(
    (source) => `${props.source}.${source}`,
    [props.source]
  );
  const { visible, disabled } = useWorkflowInput({
    ...props,
    source: "cost_plan",
  });
  const hasFormPermission = useFormPermission();
  const hasCostPlanPermission = useMemo(
    () => hasFormPermission("cost_plan"),
    [hasFormPermission]
  );

  const {
    maxValueTotalCost,
    maxValueActivitiesCost,
    minValueActivitiesCost,
    maxValueEquipmentsCost,
    disableCostPlanInput,
  } = useLimitCostPlan(props);

  if (!visible) {
    return null;
  }
  return (
    <Group wrapper {...props}>
      {disableCostPlanInput && <CostPlanAlert />}
      <GroupTitle
        title={fieldLabel("general")}
        subTitle={fieldLabel("general.help")}
      />
      <Group fullWidth>
        <GroupItem lg={4} md={4} sm={6}>
          <MoneyInput
            label={fieldLabel(`total_cost`)}
            source={fieldSource("total_cost")}
            helperText={fieldLabel(`total_cost.help`)}
            disabled={
              disabled || !hasCostPlanPermission || disableCostPlanInput
            }
            validate={[minValue(0), maxValue(maxValueTotalCost)]}
          />
        </GroupItem>
      </Group>
      <Group fullWidth>
        <GroupItem lg={4} md={4} sm={6}>
          <MoneyInput
            label={fieldLabel(`activities_cost`)}
            source={fieldSource("activities_cost")}
            helperText={fieldLabel("activities_cost.help")}
            disabled={
              disabled || !hasCostPlanPermission || disableCostPlanInput
            }
            validate={[
              minValue(minValueActivitiesCost),
              maxValue(maxValueActivitiesCost),
              validate,
            ]}
          />
        </GroupItem>
        <GroupItem lg={8} md={8}>
          <DebouncedTextInput
            label={fieldLabel(`activities_description`)}
            source={fieldSource("activities_description")}
            helperText={fieldLabel("activities_description.help")}
            maxLength={1000}
            multiline
            disabled={
              disabled || !hasCostPlanPermission || disableCostPlanInput
            }
          />
        </GroupItem>
      </Group>
      <Group fullWidth>
        <GroupItem lg={4} md={4} sm={6}>
          <MoneyInput
            label={fieldLabel(`equipments_cost`)}
            source={fieldSource("equipments_cost")}
            helperText={fieldLabel("equipments_cost.help")}
            disabled={
              disabled || !hasCostPlanPermission || disableCostPlanInput
            }
            validate={[minValue(0), maxValue(maxValueEquipmentsCost), validate]}
          />
        </GroupItem>
        <GroupItem lg={8} md={8}>
          <DebouncedTextInput
            label={fieldLabel(`equipments_description`)}
            source={fieldSource("equipments_description")}
            helperText={fieldLabel("equipments_description.help")}
            maxLength={1000}
            multiline
            disabled={
              disabled || !hasCostPlanPermission || disableCostPlanInput
            }
          />
        </GroupItem>
      </Group>
    </Group>
  );
};
export default CostPlanInput;
