import {
  FormTab,
  ValidationSummary,
  useTabVisibility,
} from "ra-friendsofbabba";

import DirtyFormAlert from "../../alert/DirtyFormAlert";
import { Fp } from "../../input";
import React from "react";

const FacilitatorPointTab0 = ({ fieldLabel, ...props }) => {
  const visible = useTabVisibility(0);
  return (
    <FormTab {...props} label={fieldLabel("tabs.section_1")}>
      {visible && <ValidationSummary />}
      {visible && <DirtyFormAlert />}
      {visible && (
        <Fp.FacilitatorPointInput source="facilitator_point" fullWidth />
      )}
    </FormTab>
  );
};
export default FacilitatorPointTab0;
