import React from "react";
import { ReferenceCheckboxGroupInput } from "ra-friendsofbabba";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(1),
    "& legend": {
      paddingBottom: theme.spacing(1),
    },
  },
}));
const ReservationMethodsInput = ({ fieldLabel, ...props }) => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <ReferenceCheckboxGroupInput
        {...props}
        reference="reservation-methods"
        sort={{ field: "id", order: "ASC" }}
        optionText="name"
        fullWidth
      />
    </div>
  );
};
export default ReservationMethodsInput;
