import {
  CreateButton,
  DateField,
  FilterButton,
  List,
  SimpleList,
  TextField,
  TopToolbar,
  usePermissions,
} from "react-admin";
import {
  Datagrid,
  EditButton,
  ListEmpty,
  SearchInput,
  StateCollectionInput,
  StateInput,
  useFieldLabel,
} from "ra-friendsofbabba";
import { ExportMenuButton, PrintButton } from "../button";
import React, { Fragment, useMemo } from "react";

import MunicipalityInput from "../input/MunicipalityInput";
import ProvinceInput from "../input/ProvinceInput";
import TimeWindowAlert from "../alert/TimeWindowAlert";
import { useMediaQuery } from "@material-ui/core";

const Actions = (props) => {
  const { loaded, permissions } = usePermissions();
  const { canCreate, isAdmin } = useMemo(
    () => ({
      canCreate: loaded && permissions(["user"]),
      isAdmin: loaded && permissions(["admin", "region"]),
    }),
    [loaded, permissions]
  );
  return (
    <TopToolbar>
      <FilterButton />
      {canCreate && (
        <CreateButton
          label="resources.projects.buttons.add"
          variant="contained"
        />
      )}
      {isAdmin && <ExportMenuButton {...props} choices={["zip"]} />}
    </TopToolbar>
  );
};

const ProjectList = (props) => {
  const { loaded, permissions } = usePermissions();
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("xs"));
  const isSmall = useMediaQuery((theme) => theme.breakpoints.down("md"));
  const { isAdmin } = useMemo(
    () => ({
      isAdmin: loaded && permissions(["admin"]),
    }),
    [loaded, permissions]
  );
  const paFieldLabel = useFieldLabel({ resource: "public-authorities" });
  return (
    <Fragment>
      <TimeWindowAlert />
      <List
        {...props}
        empty={<ListEmpty />}
        actions={<Actions />}
        bulkActionButtons={false}
        filters={[
          <SearchInput source="q" alwaysOn />,
          <StateInput source="state" alwaysOn />,
          <ProvinceInput source="province" label={paFieldLabel("province")} />,
          <MunicipalityInput
            source="municipality_id"
            label={paFieldLabel("municipality_id")}
            deps={{ province: "province" }}
          />,
        ]}
      >
        {isMobile ? (
          <SimpleList
            primaryText={(record) => record?.code}
            secondaryText={(record) =>
              `${record?.public_authority?.municipality?.name} (${record?.public_authority?.province})`
            }
          />
        ) : (
          <Datagrid rowClick="edit">
            <TextField source="code" />
            <TextField source="user.name" sortBy="UserProfiles.surname" />
            <TextField
              source="public_authority.province"
              sortBy="PublicAuthorities.province"
              label={paFieldLabel("province")}
            />
            <TextField
              source="public_authority.municipality.name"
              sortBy="Municipalities.name"
              label={paFieldLabel("municipality_id")}
            />
            {!isSmall && <DateField source="modified" showTime />}
            {!isSmall && (
              <DateField
                source="sent_transaction.created"
                showTime
                sortBy="SentTransactions.created"
              />
            )}

            <StateCollectionInput
              source="state"
              readonly={!isAdmin}
              sortable={false}
            />
            <PrintButton layout="form" />
            <EditButton />
          </Datagrid>
        )}
      </List>
    </Fragment>
  );
};

export default ProjectList;
