import { Alert, AlertTitle } from "ra-friendsofbabba";
import { ProjectFormContext, useCanSubmit } from "../form/ProjectForm";
import React, { useContext, useMemo } from "react";

import { makeStyles } from "@material-ui/core/styles";
import moment from "moment";
import { useTranslate } from "react-admin";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "70%",
    minWidth: "960px",
    maxWidth: "1024px",
    margin: "1em auto",
    [theme.breakpoints.down("md")]: {
      minWidth: "100%",
    },
  },
}));
const ProjectAlert = () => {
  const { record } = useContext(ProjectFormContext);
  const canSubmit = useCanSubmit({ record, tab: false });
  const translate = useTranslate();
  const classes = useStyles();
  const sent = useMemo(() => {
    if (!record) {
      return null;
    }
    const sentTransaction = record?.sent_transaction;
    if (!sentTransaction) {
      return null;
    }
    const created = moment(sentTransaction?.created);
    return {
      date: created.format("DD/MM/YYYY"),
      time: created.format("HH:mm"),
    };
  }, [record]);
  return (
    <Alert
      elevation={1}
      className={classes.root}
      severity={canSubmit ? "success" : "warning"}
    >
      <AlertTitle>
        {sent
          ? translate("resources.projects.alert.sent.title")
          : translate(
              `resources.projects.alert.submit.title.${
                record?.id ? "created" : "new"
              }.${canSubmit ? "submittable" : "not-submittable"}`
            )}
      </AlertTitle>
      <div
        dangerouslySetInnerHTML={{
          __html: sent
            ? translate("resources.projects.alert.sent.message", sent)
            : translate(
                `resources.projects.alert.submit.message.${
                  record?.id ? "created" : "new"
                }.${canSubmit ? "submittable" : "not-submittable"}`
              ),
        }}
      />
    </Alert>
  );
};

export default ProjectAlert;
