import { DateInput, maxValue, minValue } from "react-admin";
import {
  DebouncedNumberInput,
  DebouncedTextInput,
  Group,
  GroupItem,
  GroupTitle,
  useFieldLabel,
} from "ra-friendsofbabba";
import React, { useCallback } from "react";

import ReservationMethodsInput from "./ReservationMethodsInput";
import moment from "moment/moment";

const LIMIT_DATE = moment("2025-10-31");
const validateDurationInMonths =
  (message = "ra.validation.duration_in_months") =>
  (value, values) => {
    const activationDate = moment(values.activation_date).add(value, "months");
    if (activationDate.isAfter(LIMIT_DATE)) {
      return message;
    }
    return undefined;
  };

const DeliveryMethodInput = (props) => {
  const fieldLabel = useFieldLabel({
    resource: "facilitator-point-delivery-methods",
  });
  const fieldSource = useCallback(
    (source) => `${props.source}.${source}`,
    [props.source]
  );

  return (
    <Group wrapper {...props}>
      <GroupTitle
        title={fieldLabel("general")}
        subTitle={fieldLabel("general.help")}
      />
      <Group fullWidth>
        <GroupItem lg={12} md={12}>
          <DebouncedTextInput
            source={fieldSource(
              "facilitator_point_delivery_method_description"
            )}
            label={fieldLabel("facilitator_point_delivery_method_description")}
            helperText={fieldLabel(
              "facilitator_point_delivery_method_description.help"
            )}
            maxLength={500}
            isRequired
          />
        </GroupItem>
      </Group>
      <Group fullWidth>
        <GroupItem lg={4} md={4} sm={6}>
          <DebouncedNumberInput
            label={fieldLabel("delivery_hours")}
            source={fieldSource("delivery_hours")}
            helperText={fieldLabel("delivery_hours.help")}
            isRequired
            validate={minValue(0)}
          />
        </GroupItem>
        <GroupItem lg={5} md={5} sm={7}>
          <DebouncedNumberInput
            label={fieldLabel("reservation_hours")}
            source={fieldSource("reservation_hours")}
            helperText={fieldLabel("reservation_hours.help")}
            isRequired
          />
        </GroupItem>
        <GroupItem lg={12} md={12}>
          <DebouncedTextInput
            label={fieldLabel("description_of_motivations")}
            source={fieldSource("description_of_motivations")}
            helperText={fieldLabel("description_of_motivations.help")}
            isRequired
            maxLength={800}
            multiline
          />
        </GroupItem>
      </Group>
      <Group fullWidth>
        <GroupItem lg={12} md={12}>
          <ReservationMethodsInput
            label={fieldLabel("reservation_methods")}
            source={fieldSource("reservation_methods")}
            fieldLabel={fieldLabel}
            isRequired
          />
        </GroupItem>
      </Group>
      <Group fullWidth>
        <GroupItem lg={12} md={12}>
          <DebouncedTextInput
            label={fieldLabel("description_of_activities")}
            source={fieldSource("description_of_activities")}
            helperText={fieldLabel("description_of_activities.help")}
            isRequired
            maxLength={2000}
            multiline
          />
        </GroupItem>
        <GroupItem lg={12} md={12}>
          <DebouncedTextInput
            label={fieldLabel("description_of_digital_facilitators")}
            source={fieldSource("description_of_digital_facilitators")}
            helperText={fieldLabel("description_of_digital_facilitators.help")}
            isRequired
            maxLength={1000}
            multiline
          />
        </GroupItem>
        <GroupItem lg={12} md={12}>
          <DebouncedTextInput
            label={fieldLabel("description_of_monitoring_actions")}
            source={fieldSource("description_of_monitoring_actions")}
            helperText={fieldLabel("description_of_monitoring_actions.help")}
            isRequired
            maxLength={800}
            multiline
          />
        </GroupItem>
      </Group>
      <Group>
        <GroupItem lg={4} md={6} xs={12}>
          <DateInput
            label={fieldLabel("activation_date")}
            source={fieldSource("activation_date")}
            helperText={fieldLabel("activation_date.help")}
            validate={maxValue("2023-10-31")}
            isRequired
          />
        </GroupItem>
      </Group>
      <Group>
        <GroupItem lg={4} md={12}>
          <DebouncedNumberInput
            label={fieldLabel("duration_in_months")}
            source={fieldSource("duration_in_months")}
            helperText={fieldLabel("duration_in_months.help")}
            validate={[minValue(24), maxValue(48), validateDurationInMonths()]}
            isRequired
          />
        </GroupItem>
      </Group>
    </Group>
  );
};
export default DeliveryMethodInput;
