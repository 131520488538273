import { Confirm, useTranslate } from "react-admin";
import React, { Fragment, useCallback, useState } from "react";

import { API_URL } from "../../config";
import { Button } from "react-admin";
import PrintIcon from "@material-ui/icons/Print";
import { get } from "lodash";
import { getToken } from "ra-friendsofbabba";

const PrintButton = ({
  record,
  pristine = true,
  className,
  disabled = false,
  layout = "default",
  label,
  confirm = true,
  resource,
}) => {
  const recordId = get(record, "id", null);
  const url = `${API_URL}/${resource}/print.pdf?id=${recordId}&token=${getToken()}&layout=${layout}`;
  const translate = useTranslate();
  const [open, setOpen] = useState(false);
  const handleOpen = useCallback((e) => {
    e.stopPropagation();
    setOpen(true);
  }, []);
  const handleClose = useCallback(() => setOpen(false), []);
  const handleConfirm = useCallback(
    (e) => {
      e.stopPropagation();
      setOpen(false);
      window.open(url, "_blank");
    },
    [url]
  );
  if (recordId === null) {
    return null;
  }

  return (
    <Fragment>
      <Button
        color="primary"
        label={label || translate(`resources.projects.buttons.print_${layout}`)}
        onClick={!pristine && confirm ? handleOpen : handleConfirm}
        className={className}
        disabled={disabled}
      >
        <PrintIcon />
      </Button>
      <Confirm
        isOpen={open}
        title="resources.projects.title.print_warning"
        content="resources.projects.message.print_warning"
        onConfirm={handleConfirm}
        onClose={handleClose}
      />
    </Fragment>
  );
};
export default PrintButton;
